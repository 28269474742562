import type { Article, Group } from "../../../types/content";

const mapTeaserStyle = (dataGroup: Group) => {
  const firstItem = dataGroup[0];
  const teaserLayout =
    firstItem.type === "Box"
      ? "Box"
      : (firstItem as Article).teaser_layout || "";

  if (
    ["SponsoredLarge", "SponsoredSmall"].includes(teaserLayout) ||
    (firstItem as Article)?.sponsor
  ) {
    return "native";
  }

  if (["PremiumLarge", "PremiumSmall", "Box"].includes(teaserLayout)) {
    return "premium";
  }

  if (
    teaserLayout === "Small" &&
    firstItem.type === "Article" &&
    (firstItem as Article).meta.custom_properties.paywall
  ) {
    return "premium";
  }

  return "regular";
};

export { mapTeaserStyle };
