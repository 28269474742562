import Link from "next/link";

import styles from "./BreakingCard.module.scss";

const BreakingCard = ({
  articleLink: { articleId, slug },
  vignette,
  title,
}: {
  articleLink: { articleId: string; slug: string };
  title: string;
  vignette: string;
}) => {
  return (
    <div className={styles.breakingCard}>
      <Link
        href={`/${slug}/a/${articleId}`}
        className={styles.link}
        prefetch={true}
      >
        <span className={styles.separator}>●</span>
        <div>
          <span className={styles.vignette}>{vignette}</span>
          <span className={styles.title}>{title}</span>
        </div>
      </Link>
    </div>
  );
};

export { BreakingCard };
